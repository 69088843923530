import {Component, Self} from '@angular/core';
import {MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxDefaultOptions} from '@angular/material/checkbox';
import {ControlValueAccessor, NgControl} from '@angular/forms';

@Component({
    selector: 'ngx-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    providers: [
        {provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: {clickAction: 'noop'} as MatCheckboxDefaultOptions},
    ],
})
export class CheckboxComponent implements ControlValueAccessor {

    tape = ['', true, false];
    disabled: boolean;
    value;
    private onChange: (val: boolean) => void;
    private onTouched: () => void;

    constructor(@Self() public ngControl: NgControl) {
        ngControl.valueAccessor = this;
    }


    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

    next() {
        this.ngControl.control.patchValue(this.tape[(this.tape.indexOf(this.ngControl.value) + 1) % this.tape.length]);
    }

    writeValue = (v) => this.value = v || this.tape[0];

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }
}
