<div class="smart-table">
    <ng-content select="div[slot=filters]"></ng-content>
    <div class="table-actions">
        <button *ngIf="showAddButton"
                (click)="addButtonClick.next(null)"
                style="margin:10px 0 0 10px"
                color="primary"
                mat-mini-fab>
            <mat-icon>add</mat-icon>
        </button>
        <mat-paginator #top (page)="handlePageBottom($event)"
                       [length]="dataSource.counter|async"
                       [pageSizeOptions]="pageSizeOptions">
        </mat-paginator>
    </div>
    <section class="mat-elevation-z0">
        <table [dataSource]="dataSource" mat-table matSort>
            <ng-container *ngFor="let header of headers; let i = index"
                          [sticky]="i===0"
                          [stickyEnd]="i===headers.length - 1 && !actions.length"
                          [matColumnDef]="header.columnDef">
                <th *matHeaderCellDef mat-header-cell>
                    <div fxFill fxLayout="row" fxLayoutAlign="center center" style="max-width: 100px">
                <span [disabled]="!header.sortable"
                      [mat-sort-header]="header.columnDef">  {{ header.displayText }}</span>
                        <button *ngIf="header.sortable" [matMenuTriggerFor]="filterMenu" mat-icon-button>
                            <mat-icon>filter_alt</mat-icon>
                        </button>
                        <mat-menu #filterMenu="matMenu"
                                  [overlapTrigger]="false"
                                  xPosition="before"
                                  yPosition="below">
                            <ng-template matMenuContent>
                                <form [formGroup]="forms">

                                    <ngx-filterable-column [columnType]="header.type"
                                                           [formControlName]="header.columnDef"></ngx-filterable-column>

                                    <div fxLayout="row" fxLayoutAlign="space-evenly end">
                                        <button (click)="apply()" color="primary" mat-raised-button>Apply</button>
                                        <button (click)="clear(header.columnDef)" color="warn" mat-raised-button>Clear
                                        </button>
                                    </div>
                                </form>

                            </ng-template>
                        </mat-menu>
                    </div>
                </th>
                <td *matCellDef="let row" mat-cell>
                    <ng-container [ngSwitch]="header.type">
                        <img *ngSwitchCase="columnType.Base64Image" alt=""
                             [src]="row|getProperty:header.columnDef|toImageSource:'image/svg+xml'"
                             height="25">

                        <img *ngSwitchCase="columnType.UrlImage" alt=""
                             [src]="row|getProperty:header.columnDef|urlToAuthorizedImageSource|async"
                             height="25">

                        <span *ngSwitchCase="columnType.Default">
                            {{ !header.accessor ? (row|getProperty:header.columnDef) : header.accessor(row) }}
                        </span>
                        <span *ngSwitchCase="columnType.DateTime">
                            {{
                                (row|getProperty:header.columnDef) ?
                                    ((row|getProperty:header.columnDef) + 'Z'| date:'dd/MM/yyyy HH:mm')
                                    : null
                            }}
                        </span>
                        <span *ngSwitchCase="columnType.Date">
                            {{
                                (row|getProperty:header.columnDef) ?
                                    ((row|getProperty:header.columnDef) + 'Z' | date:'dd/MM/yyyy')
                                    : null
                            }}
                        </span>
                        <span *ngSwitchCase="columnType.Boolean">
                            {{ (row|getProperty:header.columnDef) ? 'Yes' : 'No' }}
                        </span>
                        <a *ngSwitchCase="columnType.Link"
                           [routerLink]="header.params.concat(row|getProperty:header.columnDef)"
                           role="button">
                            {{ row|getProperty:header.columnDef }}
                        </a>
                    </ng-container>
                </td>
            </ng-container>
            <ng-container *ngIf="actions.length" [stickyEnd]="actions.length" matColumnDef="actions">
                <th *matHeaderCellDef mat-header-cell>
                    <div fxFill fxLayout="row" fxLayoutAlign="space-evenly center">
                        Actions
                    </div>
                </th>
                <td (click)="$event.stopPropagation()" *matCellDef="let row" mat-cell>
                    <button [matMenuTriggerFor]="menu" *ngIf="showMenu(row)" mat-icon-button>
                        <mat-icon color="primary">menu</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu" [overlapTrigger]="false">
                        <div *ngFor="let action of actions">
                            <button (click)="action.handler(row)" *ngIf="action.isAvailable(row)" mat-menu-item>
                                <mat-icon [color]="action.color">{{ action.icon(row) }}</mat-icon>
                                <span>{{ action.title(row) }}</span>
                            </button>
                        </div>
                    </mat-menu>
                </td>
            </ng-container>
            <tr *matHeaderRowDef="displayedColumns;sticky:true" mat-header-row></tr>
            <tr *matRowDef="let row; columns: displayedColumns;" mat-row>
            </tr>
        </table>
    </section>
    <ngx-loading *ngIf="loading" class="dialog"></ngx-loading>
    <div class="table-actions auto-margin-top">
        <button *ngIf="showAddButton"
                (click)="addButtonClick.next(null)"
                style="margin:10px 0 0 10px"
                color="primary"
                mat-mini-fab>
            <mat-icon>add</mat-icon>
        </button>
        <mat-paginator #main [length]="dataSource.counter|async"
                       [pageSizeOptions]="pageSizeOptions"
                       (page)="top.pageIndex = $event.pageIndex;top.pageSize=$event.pageSize"
                       [pageSize]="pageSizeOptions[0]"></mat-paginator>
    </div>
    <ng-content select="div[slot=footer]"></ng-content>
</div>
