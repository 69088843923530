import {Component, Input, OnInit, Self} from '@angular/core';
import {ControlValueAccessor, FormControl, FormGroup, NgControl} from '@angular/forms';
import {AutoCompleteDataSource} from '@app/shared/components/autocomplete/autocomplete-data-source';
import {debounceTime} from "rxjs/operators";

@Component({
    selector: 'app-autocomplete',
    templateUrl: './autocomplete.component.html',
    styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent implements ControlValueAccessor, OnInit {
    @Input() label: string;
    @Input() dataSource: AutoCompleteDataSource;
    control = new FormControl();

    constructor(@Self() public ngControl: NgControl) {
        ngControl.valueAccessor = this;
    }

    ngOnInit(): void {
        this.control.valueChanges.pipe(debounceTime(500))
            .subscribe(value => {
                this.dataSource.resetData(value);
            });
        this.ngControl.valueChanges.subscribe(v => {
            this.control.setValue(this.dataSource.get(v).name)
        });
    }

    setValue(value) {
        this.ngControl.control.patchValue(value.id, {emitEvent: false});
    }
    writeValue = () => 0;

    onChange = () => 0;
    onTouched = () => 0;
    registerOnChange = (fn) => this.onChange = fn;
    registerOnTouched = (fn) => this.onTouched = fn;
}
