import {Component, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatStepper} from '@angular/material/stepper';
import {Router} from '@angular/router';
import {AuthService} from '@app/modules/auth/auth.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    @ViewChild('stepper') stepper: MatStepper;
    phoneControl: FormControl = new FormControl(null, Validators.required);
    codeControl: FormControl = new FormControl(null, Validators.required);
    form: FormGroup = new FormGroup({
        code: this.codeControl,
        username: this.phoneControl,
        codeId: new FormControl()
    });

    constructor(private router: Router, public authService: AuthService) {
    }

    ngOnInit() {
    }

    getCode() {
        this.authService.getCode(`+374${this.phoneControl.value}`)
            .subscribe(id => {
                this.stepper.next();
                this.form.controls.codeId.patchValue(id);
            });
    }

    login() {
        const {code, codeId, username} = this.form.value;
        this.authService.checkCode(code, codeId, `+374${username}`)
            .subscribe((tokenData) => {
                debugger
                localStorage.setItem('token', tokenData.token);
                this.authService.getCurrentUserDetails()
                    .subscribe(user => {
                        if (user)
                            localStorage.setItem('userDetails', JSON.stringify(user));
                        return this.router.navigate(['/', 'home']);
                    });
            });
    }
}

