import {ComponentPortal} from '@angular/cdk/portal';
import {Component, ComponentRef, Inject, InjectionToken, Injector, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogContent} from '@app/shared/components/dialog/dialog-content';
import {NbGlobalLogicalPosition, NbToastrService} from '@nebular/theme';

export const CONTAINER_DATA = new InjectionToken<any>('CONTAINER_DATA');

@Component({
    selector: 'ngx-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss']
})
export class DialogComponent<T extends DialogContent> implements OnInit {
    portal: ComponentPortal<T>;
    componentInstance: T;
    hideActions: true

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                public toastrService: NbToastrService,
                public injector: Injector,
                public dialogRef: MatDialogRef<DialogComponent<T>>) {
    }

    ngOnInit() {
        const {component, stayOpen, ...componentData} = this.data;
        this.hideActions = componentData.hideActions;
        const injector = Injector.create({
            parent: this.injector,
            providers: [{provide: CONTAINER_DATA, useValue: componentData.data}]
        });
        this.portal = new ComponentPortal(component, null, injector);
    }

    attached(ref: ComponentRef<T>) {
        this.componentInstance = ref.instance;
    }

    save() {
        this.componentInstance.submit()
            .subscribe(
                () => {
                    this.toastrService.show('Operation completed successfully', 'Success', {
                        status: 'success',
                        duration: 3000,
                        toastClass: 'toastClass',
                        position: NbGlobalLogicalPosition.TOP_END,
                    });
                    if (!this.data.stayOpen)
                        this.dialogRef.close(true);
                },
                () => {
                    this.dialogRef.close(false);
                });
    }
}
