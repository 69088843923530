<div class="header-container">
    <div class="logo-container">
        <a href="#" class="sidebar-toggle"
           matRipple
           [matRippleUnbounded]="true"
           [matRippleCentered]="true"
           (click)="toggleSidebar()">
            <nb-icon [icon]="'menu-outline'"></nb-icon>
        </a>
        <a class="logo" routerLink="/home" (click)="navigateHome()">Powerfull</a>
    </div>
</div>
<nb-toggle (checkedChange)="changeTheme($event)"
           [checked]="currentTheme.endsWith('dark')">
    Dark mode
</nb-toggle>
<div class="header-container" *ngIf="user">
    <nb-actions size="small">
        <nb-action
            class="user-action"
            matRipple
            [matRippleUnbounded]="true"
            [matRippleCentered]="true">
            <nb-user [nbContextMenu]="userMenu"
                     [onlyPicture]="userPictureOnly"
                     [picture]="user.avatarUrl|urlToAuthorizedImageSource|async"
                     [name]="user?.fullName">
            </nb-user>
        </nb-action>
    </nb-actions>
</div>
