import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpService, ResponseType} from '@app/@core/services/http.service';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Observable, of} from 'rxjs';

@Injectable()
export class AuthService {

    constructor(public router: Router, public http: HttpService) {
    }

    checkForAvailability(role) {
        const token = localStorage.getItem('token');
        const helper = new JwtHelperService();
        const decodedToken = helper.decodeToken(token);
        return decodedToken.role.includes(role);
    }

    getCode(phoneNumber: string): Observable<number> {
        return this.http.post('api/account', {
            deviceId: 'admin',
            firebaseToken: 'admin',
            hash: 'admin',
            phoneNumber
        });
    }

    checkCode(code, codeId, username) {
        return this.http.post('api/Account/register', {
            deviceId: 'admin',
            firebaseToken: 'admin',
            hash: 'admin',
            code,
            codeId,
            username
        });
    }

    getCurrentUserDetails(): Observable<any> {
        if (!localStorage.getItem('userDetails')) {
            return this.http.get('api/account');
        }
        return of(false);
    }

    logOut(): void {
        localStorage.clear();
        this.router.navigate(['login']);
    }


}
