import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {environment} from './environments/environment';
import {AppModule} from '@app/app.module';

const providers = [
    {provide: 'BASE_URL', useFactory: () => environment.baseUrl, deps: []},
];
if (environment.production) {
    enableProdMode();
}
platformBrowserDynamic(providers).bootstrapModule(AppModule)
    .catch(err => console.error(err));
