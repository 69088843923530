import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {Action, Column, ColumnType} from './models';
import {merge} from 'rxjs';
import {tap} from 'rxjs/operators';
import {FormControl, FormGroup} from '@angular/forms';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {TableDataSource} from '@app/shared/components/table/table-data-source';
import {SearchModel} from '@app/shared/models/search-model';
import * as moment from 'moment';
import {SearchOptions} from "@app/shared/models/search-options";

@Component({
    selector: 'ngx-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnChanges, AfterViewInit, OnInit {
    @Input() headers: Array<Column> = [];
    @Input() actions: Array<Action> = [];
    @Input() loading = true;
    @Input() dataSource: TableDataSource<any>;
    @Input() showAddButton: boolean;
    @Input() initialSort: string;
    @Output() addButtonClick: EventEmitter<any> = new EventEmitter();
    @Output() changed: EventEmitter<SearchOptions> = new EventEmitter<SearchOptions>();
    @ViewChild('main', {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    displayedColumns: string[] = [];
    columnType = ColumnType;
    pageSizeOptions = [10, 20, 50, 100];
    forms: FormGroup;
    formState = {
        term: '',
        from: '',
        to: '',
        terms: [],
        range: false,
        nonEqual: false
    };

    public handlePageBottom(event: PageEvent) {
        this.paginator.pageSize = event.pageSize;
        this.paginator.pageIndex = event.pageIndex;
        this.paginator.page.emit(event);
    }

    ngOnInit(): void {
        this.sort.direction = 'desc';
        this.sort.active = this.initialSort !== 'None' && (this.initialSort || this.headers.find(x => x.sortable)?.columnDef);
        const controls = {};
        this.headers.filter(x => x.sortable).forEach(el => controls[el.columnDef] = new FormControl(this.formState));
        this.forms = new FormGroup<any>(controls);
    }

    ngAfterViewInit() {
        merge(this.paginator.page, this.sort.sortChange)
            .pipe(tap(() => this.apply()))
            .subscribe();
        this.apply()
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.displayedColumns = [...this.headers.map(x => x.columnDef)];
        if (this.actions.length) {
            this.displayedColumns.push('actions');
        }
    }

    apply() {
        this.changed.next(this.getFilter());
    }

    clear(key: string) {
        this.forms.controls[key].patchValue(this.formState);
        this.apply()
    }

    getFilter(): SearchOptions {
        const search: SearchModel[] = [];
        this.displayedColumns.forEach(column => {
            if (this.forms.controls[column]?.value !== undefined) {
                const isDate = this.headers.find(x => x.columnDef === column).type === ColumnType.Date;
                const {term, terms, from, to, range, nonEqual} = this.forms.controls[column]?.value;
                if (term || from || to || terms.length) {
                    const model: SearchModel = {
                        property: column,
                        nonEqual: nonEqual
                    };
                    if (this.headers.find(x => x.columnDef === column).type === ColumnType.DateTime) {
                        model.from = moment(from).format(`yyyy/MM/DD[T]HH:mm:ss`).replace('Invalid date', '');
                        model.to = moment(to).format(`yyyy/MM/DD[T]HH:mm:ss`).replace('Invalid date', '');
                    }
                    if (range) {
                        model.from = from;
                        model.to = to;
                        model.nonEqual = false;
                        if (isDate) {
                            model.from = moment(from).format('yyyy-MM-DD');
                            model.to = moment(to).format('yyyy-MM-DD');
                        }
                    } else {
                        model.term = term;
                        model.terms = terms;
                        if (isDate) {
                            model.term = moment(term).format('yyyy-MM-DD');
                        }
                    }
                    search.push(model);
                }
            }
        });
        return {
            pageNumber: this.paginator.pageIndex + 1,
            pageSize: this.paginator.pageSize || this.pageSizeOptions[0],
            descendingOrder: this.sort.direction !== 'asc',
            orderProperty: this.sort.active ? this.sort.active : null,
            search
        };
    }

    showMenu(row: any) {
        return this.actions.find(a => !a.isAvailable || a.isAvailable(row));
    }
}
