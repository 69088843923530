import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatRippleModule} from '@angular/material/core';
import {NbEvaIconsModule} from '@nebular/eva-icons';
import {FooterComponent, HeaderComponent} from './components';
import {MATERIAL_LIGHT_THEME} from './styles/material/theme.material-light';
import {MATERIAL_DARK_THEME} from "@app/@theme/styles/material/theme.material-dark";
import {RouterModule} from '@angular/router';
import {
    NbActionsModule,
    NbButtonModule,
    NbContextMenuModule,
    NbIconModule,
    NbLayoutModule,
    NbMenuModule,
    NbSearchModule,
    NbSelectModule,
    NbSidebarModule,
    NbThemeModule, NbToggleModule,
    NbUserModule
} from '@nebular/theme';
import {SharedModule} from '@app/shared/shared.module';

const NB_MODULES = [
    NbLayoutModule,
    NbMenuModule,
    NbUserModule,
    NbActionsModule,
    NbSearchModule,
    NbSidebarModule,
    NbContextMenuModule,
    NbButtonModule,
    NbSelectModule,
    NbIconModule,
    NbEvaIconsModule
];
const COMPONENTS = [
    HeaderComponent,
    FooterComponent
];

@NgModule({
    imports: [CommonModule, MatRippleModule, ...NB_MODULES, RouterModule, SharedModule, NbToggleModule],
    exports: [CommonModule, MatRippleModule, ...COMPONENTS],
    declarations: [...COMPONENTS]
})
export class ThemeModule {
    static forRoot(): ModuleWithProviders<ThemeModule> {
        const themes = [MATERIAL_LIGHT_THEME, MATERIAL_DARK_THEME];
        let defaultTheme = localStorage.getItem('theme');
        if (!themes.some(t => t.name === defaultTheme)) {
            defaultTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ?
                MATERIAL_DARK_THEME.name :
                MATERIAL_LIGHT_THEME.name
        }
        return {
            ngModule: ThemeModule,
            providers: [
                ...NbThemeModule.forRoot({name: defaultTheme}, themes).providers
            ]
        };
    }
}
