import {Component, Inject} from '@angular/core';
import {DialogContent} from "@app/shared/components/dialog/dialog-content";
import {FormGroup} from "@angular/forms";
import {Observable, of} from "rxjs";
import {CONTAINER_DATA} from "@app/shared/components/dialog/dialog.component";

@Component({
    selector: 'ngx-image-viewer',
    templateUrl: './image-viewer.component.html',
    styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent implements DialogContent {

    constructor(@Inject(CONTAINER_DATA) public data) {
    }

    ngOnInit(): void {
    }

    form: FormGroup = new FormGroup({});

    submit(): Observable<any> {
        return of(true);
    }

}
