import {
    Component,
    Input,
    OnInit,
    ViewChild,
    OnChanges,
    SimpleChanges
} from '@angular/core';
import {Action, Column, ColumnType} from '@app/shared/components/table/models';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from "@angular/material/paginator";
import {FormControl} from "@angular/forms";
import {debounceTime} from "rxjs/operators";

@Component({
    selector: 'ngx-static-table',
    templateUrl: './static-table.component.html',
    styleUrls: ['./static-table.component.scss']
})
export class StaticTableComponent implements OnInit, OnChanges {
    @Input() dataSource: Array<any> = [];
    @Input() headers: Array<Column> = [];
    @Input() actions: Array<Action> = [];
    @Input() loading = true;
    @Input() showNumbering;
    @ViewChild(MatSort) sort: MatSort = new MatSort();
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    displayedColumns: string[] = [];
    columnType = ColumnType;
    source: MatTableDataSource<any>;
    search: FormControl = new FormControl('');

    constructor() {
        this.source = new MatTableDataSource([]);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.dataSource) {
            this.search.setValue('');
            this.source.data = changes.dataSource.currentValue;
        }
        this.source.sort = this.sort;
        this.source.paginator = this.paginator
    }

    ngOnInit(): void {
        this.search.valueChanges.pipe(debounceTime(300))
            .subscribe(a => this.source.data = this.dataSource.filter(d => {
                if (!a)
                    return true;
                for (let key of Object.keys(d)) {

                    if (!isNaN(parseInt(d[key])) && parseInt(d[key]?.toString()) >= parseInt(a.toLowerCase()))
                        return true

                    if (d[key]?.toString().toLowerCase().includes(a.toLowerCase()))
                        return true;
                }
                return false;
            }))
        this.displayedColumns = [...this.headers.map(x => x.columnDef)];
        if (this.showNumbering) {
            this.displayedColumns = ['No', ...this.displayedColumns];
        }
        if (this.actions.length) {
            this.displayedColumns.push('actions');
        }
    }
}
