import {Component, Input, OnInit, Self} from '@angular/core';
import {ControlValueAccessor, FormControl, FormGroup, NgControl} from '@angular/forms';
import {ColumnType} from '@app/shared/components/table/models';
import {MatChipInputEvent} from '@angular/material/chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';

@Component({
    selector: 'ngx-filterable-column',
    templateUrl: './filterable-column.component.html',
    styleUrls: ['./filterable-column.component.scss']
})
export class FilterableColumnComponent implements OnInit, ControlValueAccessor {
    type = ColumnType;
    @Input() columnType: ColumnType = ColumnType.Default;
    form: FormGroup;
    separatorKeysCodes = [ENTER, COMMA];

    constructor(@Self() public ngControl: NgControl) {
        ngControl.valueAccessor = this;
        this.form = new FormGroup({
            term: new FormControl(null),
            from: new FormControl(null),
            to: new FormControl(null),
            terms: new FormControl([]),
            range: new FormControl(false),
            nonEqual: new FormControl(false)
        });
    }

    add(event: MatChipInputEvent) {
        if (event.value) {
            const oldValue: Array<string> = this.form.controls['terms'].value;
            this.form.controls['terms'].setValue([...oldValue, event.value]);
            event.chipInput!.clear();
        }
    }

    remove(keyword: any): void {
        const oldValue: Array<string> = this.form.controls['terms'].value;
        this.form.controls['terms'].patchValue(oldValue.filter(el => el !== keyword));
    }

    onChange = () => 0;
    onTouched = () => 0;
    writeValue = () => 0;
    registerOnChange = (fn) => this.onChange = fn;
    registerOnTouched = (fn) => this.onTouched = fn;

    ngOnInit(): void {
        this.form.patchValue(this.ngControl.value)
        this.form.valueChanges.subscribe(v => this.ngControl.control.patchValue(v));
    }
}
