import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {from, Observable, of} from 'rxjs';
import {JwtHelperService} from '@auth0/angular-jwt';

@Injectable({
    providedIn: 'root'
})
export class LoggedOutGuardService implements CanActivate {

    constructor(private jwtHelper: JwtHelperService, private  router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        if (!this.isAuthenticated()) {
            localStorage.clear();
            return from(this.router.navigate(['login']));
        }
        return of(true);
    }

    isAuthenticated = (): boolean => !this.jwtHelper.isTokenExpired(localStorage.getItem('token'));
}
