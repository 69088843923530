import {Component, Input, OnInit, Self} from '@angular/core';
import {AutoCompleteDataSource} from '@app/shared/components/autocomplete/autocomplete-data-source';
import {ControlValueAccessor, FormControl, FormGroup, NgControl} from '@angular/forms';
import {COMMA, ENTER} from '@angular/cdk/keycodes';

@Component({
    selector: 'ngx-multi-select',
    templateUrl: './multi-select.component.html',
    styleUrls: ['./multi-select.component.scss']
})
export class MultiSelectComponent implements OnInit, ControlValueAccessor {
    @Input() label: string;
    @Input() dataSource: AutoCompleteDataSource;
    @Input() multiple = false;
    form = new FormGroup({searchBar: new FormControl()});
    separatorKeysCodes: number[] = [ENTER, COMMA];
    searchBar = new FormControl();


    constructor(@Self() public ngControl: NgControl) {
        ngControl.valueAccessor = this;
    }

    ngOnInit(): void {
        this.form.controls.searchBar.valueChanges
            .subscribe(value => {
                this.dataSource.resetData(value);
            });
    }

    setValue(e) {
        this.searchBar.patchValue(null, {emitEvent: false});
        this.ngControl.control.patchValue([...this.ngControl.value, e], {emitEvent: false});
    }

    remove(element: any): void {
        const index = this.ngControl.control.value.findIndex(el => el.id === element.id);

        if (index >= 0) {
            this.ngControl.control.value.splice(index, 1);
        }
    }


    onChange = () => 0;
    onTouched = () => 0;
    writeValue = () => 0;
    registerOnChange = (fn) => this.onChange = fn;
    registerOnTouched = (fn) => this.onTouched = fn;

}
