<mat-form-field>
    <input type="text"
           matInput
           [formControl]="control"
           [placeholder]="label"
           [matAutocomplete]="auto">
    <mat-hint *ngIf="ngControl.control" align="end">{{ ngControl.control.value }}</mat-hint>
    <mat-autocomplete #auto="matAutocomplete">
        <cdk-virtual-scroll-viewport class="autocomplete-test-viewport"
                                     [ngStyle]="{'height': '250px'}"
                                     itemSize="25">
            <mat-option *cdkVirtualFor="let option of dataSource"
                        [value]="option.name"
                        [id]="option.id"
                        (click)="setValue(option)"
                        multiLineOption
                        class="autocomplete-item">
                <p style="margin: unset!important;">{{ option.id }}</p>
                <p style="margin: unset!important;" [title]="option.name">{{ option.name }}</p>
            </mat-option>
        </cdk-virtual-scroll-viewport>
    </mat-autocomplete>
</mat-form-field>



