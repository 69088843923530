import {Inject, Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {HttpService, ResponseType} from '@app/@core/services/http.service';
import {catchError, map} from 'rxjs/operators';
import {of, Subject} from 'rxjs';

@Pipe({
    name: 'toImageSource'
})
export class ToImageSourcePipe implements PipeTransform {

    constructor(public sanitizer: DomSanitizer) {
    }

    transform(base64: string, type: string) {
        return this.sanitizer.bypassSecurityTrustUrl(`data:${type};base64,${base64}`);
    }

}


@Pipe({
    name: 'urlToAuthorizedImageSource'
})
export class UrlToAuthorizedImageSourcePipe implements PipeTransform {
    constructor(public sanitizer: DomSanitizer, @Inject('BASE_URL') public baseUrl: string, public http: HttpService) {
    }

    transform(url: string) {
        const observer: Subject<any> = new Subject<any>();
        if (url) {
            this.http.get(url, null, ResponseType.blob)
                .pipe(
                    map(blobResult => {
                            const reader = new FileReader();
                            reader.readAsDataURL(blobResult);
                            reader.onloadend = () => observer.next(reader.result);
                        }
                    ),
                    catchError(err => of(err))
                ).subscribe();
        } else {
            observer.next('');
        }
        return observer;
    }

}

@Pipe({
    name: 'urlToImageSource'
})
export class UrlToImageSourcePipe implements PipeTransform {
    constructor(@Inject('BASE_URL') public baseUrl: string) {
    }

    transform(url: string) {
        return `${this.baseUrl}${url}`;
    }
}
