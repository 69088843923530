<nb-layout>
    <nb-layout-column>
        <div fxLayout="column" fxFlexFill fxLayoutAlign="center center" class="container">
            <div fxLayout="column">
                <div class=" mat-elevation-z4">
                    <mat-card>
                        <mat-card-title>Admin login</mat-card-title>
                        <mat-card-content>
                            <mat-vertical-stepper linear #stepper>
                                <mat-step [stepControl]="phoneControl">
                                    <ng-template matStepLabel>Phone Number</ng-template>
                                    <mat-form-field>
                                        <mat-label>Phone number</mat-label>
                                        <input matInput
                                               type="number"
                                               maxlength="8"
                                               minlength="8"
                                               placeholder="98555777"
                                               [formControl]="phoneControl"
                                               (keydown.enter)="phoneControl.valid&&getCode()"
                                               required>
                                    </mat-form-field>
                                    <div>
                                        <button mat-button [disabled]="!phoneControl.valid" (click)="getCode()">Next
                                        </button>
                                    </div>
                                </mat-step>
                                <mat-step [stepControl]="codeControl">
                                    <ng-template matStepLabel>Confirm</ng-template>
                                    <mat-form-field>
                                        <mat-label>Code</mat-label>
                                        <input matInput type="number" minlength="4" maxlength="4"
                                               [formControl]="codeControl"
                                               (keydown.enter)="codeControl.valid&&login()"
                                               required>
                                    </mat-form-field>
                                    <div>
                                        <button mat-button matStepperPrevious>Back</button>
                                        <button mat-button [disabled]="!codeControl.valid" (click)="login()">Sign in
                                        </button>
                                    </div>
                                </mat-step>
                            </mat-vertical-stepper>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </div>
    </nb-layout-column>
</nb-layout>
