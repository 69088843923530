<div (click)="$event.stopPropagation()" fxLayout="column" fxLayoutAlign="start start" style="margin: 8px">
    <form [formGroup]="form">
        <div *ngIf="columnType===type.Default||columnType===type.Link" fxLayout="column" fxLayoutAlign="center center">
            <mat-form-field *ngIf="multiple.checked">
                <mat-chip-grid #chipList>
                    <mat-chip (removed)="remove(term)"
                              *ngFor="let term of form.get('terms').value"
                              [removable]="true">
                        {{ term }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                    <input (matChipInputTokenEnd)="add($event)"
                           [matChipInputAddOnBlur]="true"
                           [matChipInputFor]="chipList"
                           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                           placeholder="Keywords"/>
                </mat-chip-grid>
            </mat-form-field>

            <mat-form-field *ngIf="range.checked&&!multiple.checked">
                <mat-label>StartsWith</mat-label>
                <input [formControlName]="'from'" [required]="range.checked"
                       autocomplete="off" matInput style="padding: 0 5px;color: white">
                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
            <mat-form-field *ngIf="range.checked&&!multiple.checked">
                <mat-label>EndsWith</mat-label>
                <input [formControlName]="'to'"
                       [required]="range.checked"
                       autocomplete="off" matInput style="padding: 0 5px;color: white">
                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>

            <mat-form-field *ngIf="!range.checked&&!multiple.checked">
                <mat-label>Search</mat-label>
                <input [formControlName]="'term'"
                       autocomplete="off" matInput style="padding: 0 5px;color: white">
                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
        </div>

        <div *ngIf="columnType===type.DateTime" fxLayout="column" fxLayoutAlign="center center">
            <mat-form-field (click)="$event.stopPropagation();">
                <mat-label>From</mat-label>
                <input (click)="picker1.open()"
                       [formControlName]="'from'"
                       [ngxMatDatetimePicker]="picker1"
                       [required]="range.checked"
                       autocomplete="off"
                       matInput>
                <mat-datepicker-toggle [for]="picker1" matSuffix style="font-size: 20px"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #picker1 [defaultTime]="[0,0]"></ngx-mat-datetime-picker>
            </mat-form-field>
            <mat-form-field (click)="$event.stopPropagation();">
                <mat-label>To</mat-label>
                <input (click)="picker2.open()"
                       [formControlName]="'to'"
                       [ngxMatDatetimePicker]="picker2"
                       [required]="range.checked"
                       autocomplete="off"
                       matInput style="padding: 0 5px;color: white">
                <mat-datepicker-toggle [for]="picker2" matSuffix style="font-size: 20px"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #picker2 [defaultTime]="[0,0]"></ngx-mat-datetime-picker>
            </mat-form-field>
        </div>


        <div *ngIf="columnType===type.Date" fxLayout="column" fxLayoutAlign="center center">
            <mat-form-field *ngIf="range.checked">
                <mat-label>From</mat-label>
                <input (click)="picker.open()"
                       [formControlName]="'from'"
                       [matDatepicker]="picker"
                       [required]="range.checked"
                       autocomplete="off"
                       matInput>
                <mat-datepicker-toggle [for]="picker" matSuffix style="font-size: 20px"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-form-field *ngIf="range.checked">
                <mat-label>To</mat-label>
                <input (click)="picker.open()"
                       [formControlName]="'to'"
                       [matDatepicker]="picker"
                       [required]="range.checked"
                       autocomplete="off"
                       matInput style="padding: 0 5px;color: white">
                <mat-datepicker-toggle [for]="picker" matSuffix style="font-size: 20px"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <mat-form-field *ngIf="!range.checked">
                <mat-label>Search</mat-label>
                <input (click)="picker.open()"
                       [formControlName]="'term'"
                       [matDatepicker]="picker"
                       autocomplete="off"
                       matInput style="padding: 0 5px;color: white">
                <mat-datepicker-toggle [for]="picker" matSuffix style="font-size: 20px"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

        </div>

        <mat-form-field *ngIf="columnType===type.Boolean">
            <mat-select [formControlName]="'term'" style="padding: 0 5px;color: white">
                <mat-option [value]="'true'">Yes</mat-option>
                <mat-option [value]="'false'">No</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-radio-group *ngIf="!range.checked" [formControlName]="'nonEqual'" [value]="false">
            <div class="filter-options" fxFlexAlign="space-between start" fxLayout="column">
                <mat-radio-button [value]="false" color="primary">Equals</mat-radio-button>
                <mat-radio-button [value]="true" color="warn">Not Equals</mat-radio-button>
            </div>
        </mat-radio-group>
        <mat-checkbox #range (change)="range.checked?multiple.checked=false:null"
                      [checked]="true"
                      [disabled]="columnType===type.DateTime"
                      color="primary"
                      formControlName="range"
                      style="margin-left: 2px">Range
        </mat-checkbox>
        <mat-checkbox #multiple
                      [disabled]="!['Default','Link'].includes(columnType)||range.checked"
                      color="primary"
                      style="margin-left: 2px">Multiple
        </mat-checkbox>
    </form>
</div>
