import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatCardModule} from '@angular/material/card';
import {LoginComponent} from '@app/modules/auth/login/login.component';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatInputModule} from '@angular/material/input';
import {MatToolbarModule} from '@angular/material/toolbar';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {AuthService} from '@app/modules/auth/auth.service';
import {FlexModule} from '@angular/flex-layout';
import {MatFormFieldModule} from '@angular/material/form-field';
import {NbLayoutModule} from '@nebular/theme';
import {MatStepperModule} from '@angular/material/stepper';


@NgModule({
    imports: [
        MatCardModule,
        MatStepperModule,
        CommonModule,
        MatButtonModule,
        MatFormFieldModule,
        MatButtonToggleModule,
        MatInputModule,
        MatToolbarModule,
        FormsModule,
        ReactiveFormsModule,
        FlexModule,
        NbLayoutModule,
    ],
  declarations: [LoginComponent],
  exports: [RouterModule],
  providers: [AuthService]
})
export class AuthModule {
}
