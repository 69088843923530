import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'ngx-app',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit {
  constructor() {
  }

  ngOnInit(): void {
  }
}
