import {Component, OnInit} from '@angular/core';
import {DialogContent} from "@app/shared/components/dialog/dialog-content";
import {FormGroup} from "@angular/forms";
import {Observable} from "rxjs";

@Component({
    selector: 'ngx-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements DialogContent {

    constructor() {
    }

    ngOnInit(): void {
    }

    form: FormGroup = new FormGroup<any>({});

    submit(): Observable<any> {
        return undefined;
    }

}
