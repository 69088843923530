import {Component, OnDestroy, OnInit} from '@angular/core';
import {LayoutService} from '@app/@core/services/layout.service';
import {RippleService} from '@app/@core/services/ripple.service';
import {AuthService} from '@app/modules/auth/auth.service';
import {User} from '@app/shared/models/user';
import {NbMediaBreakpointsService, NbMenuItem, NbMenuService, NbSidebarService, NbThemeService} from '@nebular/theme';
import {Subject, Subscription} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';

@Component({
    selector: 'ngx-header',
    styleUrls: ['./header.component.scss'],
    templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

    userPictureOnly = false;
    user: User;
    userMenu: NbMenuItem[] = [{title: 'Log out', data: {handler: this.logOut.bind(this)}}];
    private destroy$: Subject<void> = new Subject<void>();
    private menuItemSubscription: Subscription;
    currentTheme: string;

    public constructor(
        private sidebarService: NbSidebarService,
        private menuService: NbMenuService,
        private themeService: NbThemeService,
        private rippleService: RippleService,
        private layoutService: LayoutService,
        private breakpointService: NbMediaBreakpointsService,
        public authService: AuthService,
    ) {
        this.menuItemSubscription = menuService.onItemClick()
            .subscribe((e) => e.item.data?.handler?.call());
        this.themeService.onThemeChange().subscribe(({name}) => localStorage.setItem('theme', name));
    }

    ngOnInit() {
        this.user = JSON.parse(localStorage.getItem('userDetails'));
        this.currentTheme = this.themeService.currentTheme;
        const {xl} = this.breakpointService.getBreakpointsMap();
        this.themeService.onMediaQueryChange()
            .pipe(
                map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
                takeUntil(this.destroy$),
            )
            .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

        this.themeService.onThemeChange()
            .pipe(
                map(({name}) => name),
                takeUntil(this.destroy$),
            )
            .subscribe(themeName => {
                this.currentTheme = themeName;
                this.rippleService.toggle(true);
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
        this.menuItemSubscription.unsubscribe();
    }

    changeTheme(isDark: boolean) {
        this.themeService.changeTheme(isDark ? 'material-dark' : 'material-light');
    }

    toggleSidebar(): boolean {
        this.sidebarService.toggle(true, 'menu-sidebar');
        this.layoutService.changeLayoutSize();
        return false;
    }

    navigateHome() {
        this.menuService.navigateHome();
        return false;
    }

    private logOut() {
        this.authService.logOut();
    }
}
