<button (click)="dialogRef.close(true)" *ngIf="data.stayOpen" mat-mini-fab
        style="float: right;box-shadow: unset!important;background-color: unset!important;">
    <mat-icon>close</mat-icon>
</button>
<mat-dialog-content style="text-align: center;padding: 25px;min-width: 400px">
    <ng-template (attached)="attached($event)" [cdkPortalOutlet]="portal"></ng-template>
</mat-dialog-content>
<mat-dialog-actions *ngIf="!hideActions">
    <button [mat-dialog-close]="false" color="accent" mat-flat-button mat-ripple>Cancel</button>
    <button (click)="save()" [disabled]="!componentInstance?.form.valid" [mat-dialog-close]="!data.stayOpen"
            color="warn"
            mat-flat-button mat-ripple>Ok
    </button>
</mat-dialog-actions>
