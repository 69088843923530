import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {LoginComponent} from '@app/modules/auth/login/login.component';
import {LoggedOutGuardService} from '@app/@core/services/logged-out-guard.service';
import {LoggedInGuardService} from '@app/@core/services/logged-in-guard.service';

export const routes: Routes = [
  {
    path: 'home',
    canActivate: [LoggedOutGuardService],
    loadChildren: () => import('./modules/home/home.module')
      .then(m => m.HomeModule),
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoggedInGuardService]
  },
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  {path: '**', redirectTo: 'home'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
