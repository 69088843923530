<form class="example-form" [formGroup]="form">
    <mat-form-field>
        <mat-label>{{label}}</mat-label>
        <mat-chip-grid #chipList aria-label="Fruit selection">
            <mat-chip
                *ngFor="let item of this.ngControl.control.value"
                (removed)="remove(item)">
                {{item.name}}
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input
                [placeholder]="label"
                [formControl]="searchBar"
                [matAutocomplete]="auto"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
        </mat-chip-grid>

        <mat-autocomplete #auto="matAutocomplete">
            <cdk-virtual-scroll-viewport class="autocomplete-test-viewport"
                                         [ngStyle]="{'height': '250px'}"
                                         itemSize="25">
                <mat-option *cdkVirtualFor="let option of dataSource"
                            [value]="option.name"
                            [id]="option.id"
                            (click)="setValue(option)"
                            class="autocomplete-item">
                    {{option.name}}
                </mat-option>
            </cdk-virtual-scroll-viewport>
        </mat-autocomplete>
    </mat-form-field>
</form>


