import {Component, Input, Self} from '@angular/core';
import {ControlValueAccessor, NgControl} from '@angular/forms';
import {MatCheckboxChange} from '@angular/material/checkbox';

@Component({
    selector: 'app-multi-checkbox',
    templateUrl: './multi-checkbox.component.html',
    styleUrls: ['./multi-checkbox.component.scss']
})
export class MultiCheckboxComponent implements ControlValueAccessor {
    @Input() source: Array<string>;

    constructor(@Self() public ngControl: NgControl) {
        ngControl.valueAccessor = this;
    }

    click(e: MatCheckboxChange) {
        const _ = e.checked ? this.ngControl.control.patchValue([...this.ngControl.control.value, e.source.id])
            : this.ngControl.control.patchValue([...this.ngControl.control.value.filter(x => x !== e.source.id)]);
    }

    onChange = () => 0;
    onTouched = () => 0;
    writeValue = () => 0;
    registerOnChange = (fn) => this.onChange = fn;
    registerOnTouched = (fn) => this.onTouched = fn;

}
