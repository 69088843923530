import {PortalModule} from '@angular/cdk/portal';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MAT_PAGINATOR_DEFAULT_OPTIONS, MatPaginatorModule} from '@angular/material/paginator';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {RouterModule} from '@angular/router';
import {AutocompleteComponent} from '@app/shared/components/autocomplete/autocomplete.component';
import {CheckboxComponent} from '@app/shared/components/checkbox/checkbox.component';
import {DialogComponent} from '@app/shared/components/dialog/dialog.component';
import {DialogService} from '@app/shared/components/dialog/dialog.service';
import {LoadingComponent} from '@app/shared/components/loading/loading.component';
import {MultiCheckboxComponent} from '@app/shared/components/multi-checkbox/multi-checkbox.component';
import {MultiSelectComponent} from '@app/shared/components/multi-select/multi-select.component';
import {FilterableColumnComponent} from '@app/shared/components/table/filterable-column/filterable-column.component';
import {TableComponent} from '@app/shared/components/table/table.component';
import {GetPropertyPipe} from '@app/shared/helpers/get-property.pipe';
import {SplitWordsPipe} from '@app/shared/helpers/split-words.pipe';
import {
    ToImageSourcePipe,
    UrlToAuthorizedImageSourcePipe,
    UrlToImageSourcePipe
} from '@app/shared/helpers/to-image-source.pipe';
import {StaticTableComponent} from './components/static-table/static-table.component';
import {MultiLineOptionDirective} from './helpers/multi-line-option.directive';
import {ConfirmationDialogComponent} from './components/confirmation-dialog/confirmation-dialog.component';
import {ImageViewerComponent} from './components/image-viewer/image-viewer.component';
import {EnumKeyValuePipe} from "@app/shared/helpers/enum-key-value.pipe";
import {NgxMatDatetimePickerModule, NgxMatNativeDateModule} from "@angular-material-components/datetime-picker";


@NgModule({
    declarations: [
        GetPropertyPipe,
        ToImageSourcePipe,
        UrlToAuthorizedImageSourcePipe,
        UrlToImageSourcePipe,
        SplitWordsPipe,
        TableComponent,
        MultiCheckboxComponent,
        AutocompleteComponent,
        DialogComponent,
        LoadingComponent,
        MultiSelectComponent,
        CheckboxComponent,
        StaticTableComponent,
        MultiLineOptionDirective,
        FilterableColumnComponent,
        ConfirmationDialogComponent,
        ImageViewerComponent,
        EnumKeyValuePipe
    ],
    imports: [
        CommonModule,
        MatSortModule,
        MatTableModule,
        MatInputModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatMenuModule,
        MatIconModule,
        MatPaginatorModule,
        MatCardModule,
        ScrollingModule,
        MatAutocompleteModule,
        MatDialogModule,
        PortalModule,
        MatChipsModule,
        MatGridListModule,
        MatCheckboxModule,
        MatRadioModule,
        MatTabsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        FlexModule,
        FormsModule,
        RouterModule,
        MatSelectModule,
        MatRippleModule,
        NgxMatDatetimePickerModule,
        NgxMatNativeDateModule
    ],
    exports: [
        FlexModule,
        MatTabsModule,
        MatCardModule,
        MatRadioModule,
        TableComponent,
        LoadingComponent,
        ToImageSourcePipe,
        CheckboxComponent,
        StaticTableComponent,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatInputModule,
        MatMenuModule,
        MatIconModule,
        UrlToAuthorizedImageSourcePipe,
        UrlToImageSourcePipe,
        MultiLineOptionDirective,
        MatRippleModule,
        MultiSelectComponent,
        MatChipsModule,
        AutocompleteComponent,
        EnumKeyValuePipe
    ],
    providers: [
        DialogService,
        {
            provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
            useValue: {
                formFieldAppearance: 'fill'
            }
        }
    ]
})
export class SharedModule {
}
