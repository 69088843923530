import {Injectable} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {DialogComponent} from '@app/shared/components/dialog/dialog.component';
import {ImageViewerComponent} from "@app/shared/components/image-viewer/image-viewer.component";

@Injectable()
export class DialogService {

    constructor(public dialog: MatDialog) {
    }

    openDialog(data, component, stayOpen = false) {
        const config = new MatDialogConfig();
        config.disableClose = data;
        config.autoFocus = true;
        config.role = 'dialog';
        config.data = {data, component, stayOpen};
        return this.dialog.open(DialogComponent, config).afterClosed();
    }

    showImage(data: string, component = ImageViewerComponent) {
        const config = new MatDialogConfig();
        config.autoFocus = true;
        config.role = 'dialog';
        config.data = {data, component, hideActions: true};
        return this.dialog.open(DialogComponent, config).afterClosed();
    }
}
