export interface Column {
    columnDef: string;
    sortable: boolean;
    displayText: string;
    type: ColumnType;
    params?: string[];
    accessor?: Function;
}

export enum ColumnType {
    Default = 'Default',
    UrlImage = 'UrlImage',
    Link = 'Link',
    DateTime = 'DateTime',
    Date = 'Date',
    Base64Image = 'Base64Image',
    Boolean = 'Boolean'
}
