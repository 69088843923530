<div class="search-bar">
    <mat-form-field>
        <mat-label>Filter</mat-label>
        <input [formControl]="search" matInput>
    </mat-form-field>
</div>
<ng-content select="div[slot=filters]"></ng-content>
<table mat-table [dataSource]="source" matSort>
    <ng-container [matColumnDef]="'No'" *ngIf="showNumbering">
        <th mat-header-cell *matHeaderCellDef>No.</th>
        <td mat-cell *matCellDef="let row;let i = index">{{i + 1}}</td>
    </ng-container>
    <ng-container [matColumnDef]="header.columnDef" *ngFor="let header of headers">
        <th mat-header-cell *matHeaderCellDef [mat-sort-header]="header.columnDef"> {{header.displayText}}</th>
        <td mat-cell *matCellDef="let row">
            <ng-container [ngSwitch]="header.type">
                <img height="25" *ngSwitchCase="columnType.UrlImage"
                     [src]="row|getProperty:header.columnDef|urlToAuthorizedImageSource|async">

                <span style="margin: 0 5px" *ngSwitchCase="columnType.Default">
                            {{row|getProperty:header.columnDef}}
                        </span>

                <span style="margin: 0 5px" *ngSwitchCase="columnType.Date">
                            {{
                    (row|getProperty:header.columnDef) ?
                        ((row|getProperty:header.columnDef) + 'Z' | date:'short')
                        : null
                    }}
                        </span>
                <span *ngSwitchCase="columnType.Boolean">
                            {{(row|getProperty:header.columnDef) ? 'Yes' : 'No'}}
                        </span>
                <a role="button"
                   [routerLink]="header.params.concat(row|getProperty:header.columnDef)"
                   *ngSwitchCase="columnType.Link">
                    {{row|getProperty:header.columnDef}}
                </a>
            </ng-container>
        </td>
    </ng-container>
    <ng-container matColumnDef="actions" *ngIf="actions.length">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let row" (click)="$event.stopPropagation()">
            <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon color="primary">menu</mat-icon>
            </button>
            <mat-menu #menu="matMenu" [overlapTrigger]="false">
                <div *ngFor="let action of actions">
                    <button mat-menu-item (click)="action.handler(row)" *ngIf="action.isAvailable(row)">
                        <mat-icon [color]="action.color">{{action.icon(row)}}</mat-icon>
                        <span>{{action.title(row)}}</span>
                    </button>
                </div>
            </mat-menu>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
    </tr>
</table>
<ngx-loading class="dialog" *ngIf="loading"></ngx-loading>
<mat-paginator [length]="dataSource.length"
               [pageSize]="10"
               [pageSizeOptions]="[ 10, 20,50, 100]"
               aria-label="Select page">
</mat-paginator>
<ng-content select="div[slot=footer]"></ng-content>
